import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import FullWidthSection from "../components/FullWidthSection"
import {
  colors,
  fonts,
  weights,
  container,
  mediaQueries,
  contValues,
} from "../styles"

const About = () => {
  const message = css`
    color: ${colors.reallydarkgray};
    font-family: ${fonts.sans};
    font-size: 16px;
    font-weight: ${weights.light};
    letter-spacing: 0.2px;
    line-height: 1.69;
    padding-top: 10px;
    text-align: start;

    ${mediaQueries.phoneLarge} {
      width: ${contValues.min};
    }
  `

  const lastRow = css`
    border-bottom: none;
  `

  const sign = css`
    ${container.medium};
    text-align: end;
    margin: 0 0 1.45rem;
  `

  return (
    <Layout
      headerData={{
        title: "会社案内",
        mobileMinHeight: "93vh",
        height: "400px",
      }}
    >
      <FullWidthSection
        textAlign="center"
        height="100%"
        css={css`
          ${container.medium};
          padding-top: 20px;

          ${mediaQueries.phoneLarge} {
            padding-top: 150px;
            margin-bottom: 58px;
          }
        `}
      >
        <h3>会社概要</h3>
        <table>
          <tbody>
            <tr>
              <td>社名</td>
              <td>シードシステム株式会社 （英文表記）Seed System Co.Ltd.</td>
            </tr>
            <tr>
              <td>設立年月日</td>
              <td>2006年4月20日</td>
            </tr>
            <tr>
              <td>所在地</td>
              <td>
                〒110-0005
                <br />
                東京都台東区上野3-18-13今井ビル2F
              </td>
            </tr>
            <tr>
              <td>資本金</td>
              <td>1000万円</td>
            </tr>
            <tr>
              <td>代表者</td>
              <td>代表取締役社長 志　方</td>
            </tr>
            <tr>
              <td>事業内容</td>
              <td>
                <li>ビジネスパッケージソフトの開発・販売</li>
                <li>システムコンサルティング</li>
                <li>システム開発請負</li>
                <li>エンジニア・アウトソーシング事業</li>
                <li>Lotus Notes・マイグレーション事業</li>
                <li>モバイルソリューション事業</li>
              </td>
            </tr>
            <tr>
              <td>従業員数</td>
              <td>65名(2015年12月1日現在)</td>
            </tr>
            <tr>
              <td>お問い合わせ・ご相談</td>
              <td>
                お問い合わせフォームより24時間受付中です
                <br />
                お電話によるお問い合わせ：03-3833-5681
              </td>
            </tr>
            <tr>
              <td>TEL</td>
              <td>
                <a href="tel:0338335681">03-3833-5681</a>
                （代表）（受付時間：9:30～18:30 土日祝祭日を除く）
              </td>
            </tr>
            <tr>
              <td>FAX</td>
              <td>
                <a href="fax:0338335682">03-3833-5682</a>
              </td>
            </tr>
            <tr>
              <td>URL</td>
              <td>
                <a href="https://www.seedsystem.co.jp">
                  https://www.seedsystem.co.jp
                </a>
              </td>
            </tr>
            <tr>
              <td css={lastRow}>メールアドレス</td>
              <td css={lastRow}>
                <a href="mailto:zhifang@seedsystem.co.jp">
                  zhifang@seedsystem.co.jp
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </FullWidthSection>
      <FullWidthSection
        height="100%"
        css={css`
          background-color: ${colors.lightblue};
          padding-top: 20px;
          z-index: 1;

          ${mediaQueries.phoneLarge} {
            padding-top: 150px;
            padding-bottom: 50px;
          }
        `}
      >
        <h3>社長メッセージ</h3>
        <p css={message}>
          シードシステムの社員は、常に広い視野から「ひねって」そして「突っ込んだ」、最高のアドバイスをお客様に提供しています。誰もが「お客様の最も信頼できるアドバイザリー・スタッフであれ」と常に意識しています。「もの作りが好き」な社員ばかりですが、イスに座っているだけの仕事はしません。お客様と常に話し合い、新しいものを吸収し、より良いものを生み出すことに生きがいを感じている社員ばかりです。営業、技術者、コールセンターのスタッフは、お客様が知りたい投資対効果と実現性、将来の継続性をも理解し、しっかりとした説明を心がけています。最新の情報システムを、常に変わらぬ人間の熱意で構築することで、お客様の利益創造に貢献する。そのために深い洞察力を持ち、成功まで諦めないことが、
          シードシステムの存在意義でもあり、社員全員の確信でもあるのです。
        </p>
        <h2 css={sign}>志　方</h2>
      </FullWidthSection>
    </Layout>
  )
}

export default About
